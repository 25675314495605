import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import {
  MdTimer,
  MdPersonOutline,
  MdAccessTime,
  MdFileDownload,
  MdError,
  MdSentimentSatisfied,
  MdModeEdit,
  MdContentCopy,
  MdComment,
  MdClose,
} from 'react-icons/md';
import * as Yup from 'yup';

import AppError from '../../../../errors/AppError';

import api from '../../../../services/api';

import getValidationErrors from '../../../../utils/getValidationErrors';
import convertSecondsToTime from '../../../../utils/convertSecondsToTime';

import { useMark } from '../../../../hooks/mark';
import { useToast } from '../../../../hooks/toast';
import { GradesProvider, useGrades } from '../../../../hooks/grades';

import CorrectionGrades from '../../../../containers/CorrectionGrades';
import Breadcrumbs from '../../../../containers/Breadcrumbs';
import WordingContent from '../../../../containers/WordingContent';

import Modal, { ModalHeader } from '../../../../components/Modal';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import Rating from '../../../../components/Rating';
import Card from '../../../../components/Card';
import TextArea from '../../../../components/TextArea';
import Badge from '../../../../components/Badge';
import Loader from '../../../../components/Loader';

import {
  WordingTitle,
  WordingInfo,
  CorrectionInfo,
  ReportButton,
  CorrectionPagination,
  CorrectionPaginationItem,
  RatingReason,
} from './styles';
import formatDate from '../../../../utils/formatDate';

interface CorrectionData {
  id: string;
  created_at: string;
  corrected_in: number;
  note: string;
  status: boolean;
  disapprove_reason?: string;
  final_grade?: number;
  rating?: number;
  rating_reason?: string;
  user?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
}

interface CorrectionContentProps {
  fontSize: number;
  text: string;
  created_at: string;
  end_time_in_seconds?: number;
  status: string;
  corrections?: CorrectionData[];
  student?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
  theme?: {
    name: string;
    material_url: string;
  };
  status_info?: {
    name: string;
    color:
      | 'error'
      | 'success'
      | 'info'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'warning';
  };
  setCancelWordingModal(value: boolean): void;
}

interface CorrectionGrade {
  name:
    | 'competence_1'
    | 'competence_2'
    | 'competence_3'
    | 'competence_4'
    | 'competence_5';
  value: number;
}

interface Grades {
  competence_1: number;
  competence_2: number;
  competence_3: number;
  competence_4: number;
  competence_5: number;
}

const CorrectionContent: React.FC<CorrectionContentProps> = ({
  created_at,
  end_time_in_seconds,
  status,
  text,
  corrections,
  student,
  theme,
  status_info,
  fontSize,
  setCancelWordingModal,
}) => {
  const { setInitialMarks, marks, deletedMarks } = useMark();
  const { finalGrade } = useGrades();
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const formCancelRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [modalCancel, setModalCancel] = useState(false);

  const [grades, setGrades] = useState<Grades>({
    competence_1: 0,
    competence_2: 0,
    competence_3: 0,
    competence_4: 0,
    competence_5: 0,
  });
  const [showRatingReason, setShowRatingReason] = useState(false);

  const [selectedCorrection, setSelectedCorrection] = useState<CorrectionData>(
    {} as CorrectionData,
  );

  useEffect(() => {
    if (corrections && corrections[0]) {
      setSelectedCorrection(corrections[0]);
    }
  }, [corrections]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(
          `/corrections/${selectedCorrection.id}/marks`,
        );

        setInitialMarks(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro.',
        });

        history.push('/redacoes');
      }
    }

    selectedCorrection.id && loadData();
  }, [selectedCorrection.id, addToast, history, setInitialMarks]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<CorrectionGrade[]>(
          `/corrections/${selectedCorrection.id}/grades`,
        );

        const newGrades: Grades = {} as Grades;

        response.data.forEach(data => {
          newGrades[data.name] = data.value;
        });

        formRef.current?.setData({
          ...newGrades,
        });

        setGrades(newGrades);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro.',
        });

        history.push('/redacoes');
      }
    }

    selectedCorrection.id && loadData();
  }, [selectedCorrection.id, addToast, history]);

  const handleCopyWording = useCallback(() => {
    setLoading(true);

    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        navigator.clipboard.writeText(text).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'A redação foi copiada com sucesso.',
              type: 'success',
            });

            setLoading(false);
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar a redação.',
              type: 'error',
            });

            setLoading(false);
          },
        );
      }
    });
  }, [text, addToast]);

  const handleCancel = useCallback(
    async formData => {
      setLoadingModal(true);

      try {
        const schema = Yup.object().shape({
          disapprove_reason: Yup.string().required('Motivo obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.delete(`corrections/${selectedCorrection.id}`, {
          data: {
            disapprove_reason: formData.disapprove_reason,
          },
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cancelamento',
          description: 'A correção foi cancelada com sucesso!',
        });

        setSelectedCorrection(oldCorrection => ({
          ...oldCorrection,
          disapprove_reason: formData.disapprove_reason,
          status: false,
        }));

        setModalCancel(false);
      } catch (error) {
        // console.log(error);

        addToast({
          type: 'error',
          title: 'Erro no cancelamento',
          description: 'Ocorreu um erro no cancelamento, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast, selectedCorrection.id],
  );

  const handleSubmit = useCallback(
    async formData => {
      setLoading(true);

      try {
        const positiveMarks = marks.filter(mark => mark.type === 'positive');

        if (!positiveMarks.length) {
          throw new AppError(
            'Ocorreu um erro ao enviar a correção',
            'Por favor, realize ao menos uma marcação positiva e tente novamente.',
          );
        }

        const schema = Yup.object().shape({
          note: Yup.string().required('Comentários obrigatórios'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`corrections/${selectedCorrection.id}`, {
          note: formData.note,
          final_grade: finalGrade,
          grades: [
            { name: 'competence_1', value: formData.competence_1 },
            { name: 'competence_2', value: formData.competence_2 },
            { name: 'competence_3', value: formData.competence_3 },
            { name: 'competence_4', value: formData.competence_4 },
            { name: 'competence_5', value: formData.competence_5 },
          ],
          marks: marks.filter(mark => mark.isNew),
          deletedMarksIds: deletedMarks,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na confirmação',
          description: 'A correção foi confirmada com sucesso!',
        });

        setLoading(false);

        history.push('/redacoes');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err instanceof AppError) {
          addToast({
            type: 'error',
            title: err.title,
            description: err.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, finalGrade, history, marks, selectedCorrection.id, deletedMarks],
  );

  return (
    <>
      <Container>
        {loading && <Loader isFixed />}
        <Breadcrumbs
          title="Visualizar redação"
          items={[
            { title: 'Redações', link: '/redacoes' },
            { title: 'Visualizar redação' },
          ]}
        >
          {status !== 'corrected' && (
            <>
              <ReportButton
                type="button"
                onClick={() => {
                  setCancelWordingModal(true);
                }}
              >
                <MdError size={20} />
                Cancelar redação
              </ReportButton>

              <Button form="correctionForm" type="submit" color="secondary">
                Enviar correção
              </Button>
            </>
          )}
        </Breadcrumbs>

        <CorrectionInfo>
          <div>
            <span>Modelo ENEM</span>

            <Badge
              title={status_info ? status_info.name : ''}
              color={status_info ? status_info.color : 'error'}
            />
          </div>

          <CorrectionPagination>
            {corrections?.map((correction, index) => {
              const isActive = correction.id === selectedCorrection.id;

              return (
                <CorrectionPaginationItem
                  key={correction.id}
                  isActive={isActive}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedCorrection(correction)}
                  >
                    {index + 1}
                  </button>
                </CorrectionPaginationItem>
              );
            })}
          </CorrectionPagination>
        </CorrectionInfo>

        <Card>
          <WordingInfo>
            <section>
              <h2>Informações do aluno</h2>

              <div>
                <img src={student?.avatar_url} alt={student?.show_name} />

                <ul>
                  <li>
                    <MdPersonOutline />
                    <Link to={`/alunos/visualizar/${student?.id}`}>
                      {student?.show_name}
                    </Link>
                  </li>
                  <li>
                    <MdAccessTime />
                    {created_at &&
                      formatDate(created_at, "dd/MM/yyyy 'às' HH:mm")}
                  </li>
                  <li>
                    <MdTimer />
                    {end_time_in_seconds
                      ? convertSecondsToTime(end_time_in_seconds)
                      : 'indefinido'}
                  </li>
                  <li>
                    <MdSentimentSatisfied />
                    {selectedCorrection && selectedCorrection.rating ? (
                      <>
                        <Rating
                          min={0}
                          max={50}
                          value={selectedCorrection.rating}
                          readOnly
                        />

                        {selectedCorrection.rating_reason && (
                          <RatingReason
                            content={selectedCorrection.rating_reason}
                            isActive={showRatingReason}
                            onClick={() => {
                              setShowRatingReason(!showRatingReason);
                            }}
                          >
                            <MdComment />
                            comentários
                          </RatingReason>
                        )}
                      </>
                    ) : (
                      'Não avaliado'
                    )}
                  </li>
                </ul>
              </div>
            </section>

            <section>
              <h2>Informações da correção</h2>

              <div>
                {selectedCorrection.user ? (
                  <>
                    <img
                      src={selectedCorrection.user.avatar_url}
                      alt={selectedCorrection.user.show_name}
                    />

                    <ul>
                      <li>
                        <MdPersonOutline />
                        <Link
                          to={`/usuarios/visualizar/${selectedCorrection.user.id}`}
                        >
                          {selectedCorrection.user.show_name}
                        </Link>
                      </li>
                      <li>
                        <MdAccessTime />
                        {selectedCorrection.created_at &&
                          formatDate(
                            selectedCorrection.created_at,
                            "dd/MM/yyyy 'às' HH:mm",
                          )}
                      </li>
                      <li>
                        <MdTimer />
                        {selectedCorrection.corrected_in
                          ? convertSecondsToTime(
                              selectedCorrection.corrected_in,
                            )
                          : 'indefinido'}
                      </li>
                      <li>
                        <Badge
                          title={
                            selectedCorrection.status ? 'Ativa' : 'Inativa'
                          }
                          color={
                            selectedCorrection.status ? 'success' : 'error'
                          }
                          tooltip={selectedCorrection.disapprove_reason}
                        />

                        <button
                          type="button"
                          onClick={() => setModalCancel(true)}
                        >
                          <MdModeEdit />
                          alterar
                        </button>
                      </li>
                    </ul>
                  </>
                ) : (
                  <p>Essa redação ainda não foi corrigida. 🥲</p>
                )}
              </div>
            </section>
          </WordingInfo>
        </Card>

        <Row>
          <Col md={12}>
            <Card>
              <WordingTitle>
                <h2>{theme?.name}</h2>

                <ul>
                  <li>
                    <a href={theme?.material_url} target="blank">
                      <MdFileDownload size={16} />
                      Material de apoio
                    </a>
                  </li>

                  <li>
                    <button type="button" onClick={handleCopyWording}>
                      <MdContentCopy size={16} />
                      Copiar redação
                    </button>
                  </li>
                </ul>
              </WordingTitle>

              <WordingContent
                wordingText={text}
                readOnly={!(status === 'in_confirmation')}
                fontSize={fontSize}
                student={student}
              />
            </Card>
          </Col>

          <Col md={12}>
            <Form
              ref={formRef}
              id="correctionForm"
              initialData={selectedCorrection}
              onSubmit={handleSubmit}
              noValidate
            >
              <GradesProvider
                initialFinalGrade={selectedCorrection.final_grade}
                initialGrades={grades}
              >
                <CorrectionGrades />
              </GradesProvider>

              <TextArea
                name="note"
                label="Comentários"
                required
                placeholder="Insira um breve comentário final sobre a redação e o que pode ser melhorado."
                rows={8}
              />
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={modalCancel}
        setIsOpen={() => setModalCancel(!modalCancel)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Cancelar correção</div>

          <button type="button" onClick={() => setModalCancel(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formCancelRef} onSubmit={handleCancel} noValidate>
          <Select
            label="Motivo"
            name="disapprove_reason"
            options={[
              {
                label: 'Correção incompleta',
                value: 'Correção incompleta',
              },
              {
                label: 'Correção duplicada',
                value: 'Correção duplicada',
              },
            ]}
          />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Confirmar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CorrectionContent;
