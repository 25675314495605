import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdClose, MdThumbDown, MdThumbUp } from 'react-icons/md';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useMark } from '../../hooks/mark';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';

import MarkEditor from '../../components/MarkEditor';
import Button from '../../components/Button';
import Modal, { ModalHeader } from '../../components/Modal';
import Select from '../../components/Select';
import Loader from '../../components/Loader';

import Mark from './Mark';

import {
  Container,
  FooterButtons,
  MarkSelection,
  SelectedText,
} from './styles';
import getCompetenceColor from '../../utils/getCompetenceColor';
import { getOpenAIResponse } from '../../utils/ia';
import Student from '../../interfaces/Student';
import { User } from '../../hooks/auth';

interface WordingContentProps {
  wordingText: string;
  fontSize?: number;
  readOnly?: boolean;
  student?: Student;
}

interface IMarkPosition {
  from_index: number;
  to_index: number;
  selectedText: string;
}

interface ICorrectionMark {
  id: string;
  category_id?: string;
  type: 'negative' | 'positive';
  background: string;
  color: string;
  title: string;
  description: string;
  from_index: number;
  to_index: number;
}

interface IMark {
  value: string;
  label: string;
  competence: 1 | 2 | 3 | 4 | 5;
}

interface MarkOptions {
  label: string;
  options: IMark[];
}

interface WordingContent {
  content: string;
  mark?: ICorrectionMark;
}

interface Wording {
  id: string;
  contents: WordingContent[];
}

interface MarkFormData {
  category_id: string;
  description: string;
}

interface Color {
  color: string;
  background: string;
}

const WordingContent: React.FC<WordingContentProps> = ({
  wordingText,
  fontSize = 18.5,
  readOnly = false,
  student,
}) => {
  const wordingRef = useRef<HTMLDivElement>(null);
  const formAddMarkRef = useRef<FormHandles>(null);

  const { marks, addMark } = useMark();
  const { addToast } = useToast();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingIa, setLoadingIa] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);

  const [addMarkModalOpen, setAddMarkModalOpen] = useState(false);

  const [wording, setWording] = useState<Wording[]>([]);

  const [markPosition, setMarkPosition] = useState<IMarkPosition>(
    {} as IMarkPosition,
  );
  const [markType, setMarkType] = useState<'positive' | 'negative'>('negative');

  const [markCategories, setMarkCategories] = useState<IMark[]>([]);
  const [markCategoriesOptions, setMarkCategoriesOptions] = useState<
    MarkOptions[]
  >([]);

  const handleAddMark = useCallback(
    async (formData: MarkFormData) => {
      setLoadingModal(true);

      formAddMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          category_id:
            markType === 'negative'
              ? Yup.string().required('Categoria obrigatória').nullable()
              : Yup.string(),
          description:
            markType === 'negative'
              ? Yup.string()
              : Yup.string().required('Comentário obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        setLoadingModal(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro da marcação',
          description:
            'Ocorreu um erro no cadastro da marcação, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }

      const { category_id, description } = formData;
      const { from_index, to_index } = markPosition;

      let competence = 0;
      let title = 'Marcação positiva';

      if (markType === 'negative') {
        const markCategory = markCategories.find(
          findMarkCategory => findMarkCategory.value === category_id,
        );

        if (markCategory) {
          competence = markCategory.competence;
          title = markCategory.label;
        }
      }

      const competenceColor = getCompetenceColor(competence);

      addMark({
        from_index,
        to_index,
        background: competenceColor.background,
        color: competenceColor.color,
        title,
        description,
        type: markType,
      });

      setAddMarkModalOpen(false);
    },
    [markPosition, markType, markCategories, addMark, addToast],
  );

  const deselection = useCallback((): void => {
    const getSelection = window.getSelection();

    if (getSelection) {
      if (getSelection.empty) {
        getSelection.empty();
      } else if (getSelection.removeAllRanges) {
        getSelection.removeAllRanges();
      }
    }

    wordingRef.current?.blur();
  }, []);

  const validateMarker = useCallback(
    (range: Range): boolean => {
      if (range.toString().length < 3) {
        deselection();
        return false;
      }

      if (range.commonAncestorContainer.parentElement) {
        if (range.commonAncestorContainer.parentElement.classList.length > 0) {
          deselection();
          return false;
        }
      }

      if (range.cloneContents().querySelectorAll('div').length > 0) {
        deselection();
        return false;
      }

      return true;
    },
    [deselection],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<IMark[]>('/marks/list');

        setMarkCategories(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        addToast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
          type: 'error',
        });

        history.push('/');
      }
    }

    loadData();
  }, [addToast, history]);

  useEffect(() => {
    const groupedMarkCategories: MarkOptions[] = [];

    markCategories.forEach(markCategory => {
      const competence = `Competência ${markCategory.competence}`;

      const groupIndex = groupedMarkCategories.findIndex(
        groupedMarkCategory => groupedMarkCategory.label === competence,
      );

      if (groupIndex === -1) {
        groupedMarkCategories.push({
          label: competence,
          options: [markCategory],
        });
      } else {
        groupedMarkCategories[groupIndex].options.push(markCategory);
      }
    });

    setMarkCategoriesOptions(groupedMarkCategories);
  }, [markCategories]);

  useEffect(() => {
    if (!readOnly) {
      const currentWording = wordingRef.current;

      if (currentWording) {
        const preventDefault = (event: Event) => {
          event.preventDefault();
          return false;
        };

        currentWording.addEventListener('keypress', preventDefault);
        currentWording.addEventListener('keydown', preventDefault);
        currentWording.addEventListener('paste', preventDefault);
        currentWording.addEventListener('drop', preventDefault);
        currentWording.addEventListener('focus', preventDefault);
        currentWording.addEventListener('input', preventDefault);
        currentWording.addEventListener('contextmenu', preventDefault);
      }
    }
  }, [readOnly]);

  useEffect(() => {
    if (wordingText) {
      const tempWording: Wording[] = [];
      const tempMarks = marks;

      const splitByParagraph = wordingText.split('\n');
      const removeSpaces = splitByParagraph.map(p => p.trim());

      const newText = removeSpaces.join('\n');

      const tempTexts = [newText];

      let provisoryText: string[] = [];

      tempMarks.forEach(tempMark => {
        let realStartIndex = 0;
        let indexPosition = 0;

        tempTexts.every((tempText, index) => {
          if (tempText.startsWith('[mark=') || tempText === '[/mark]') {
            return true;
          }

          if (realStartIndex + tempText.length > tempMark.from_index) {
            const startIndex = tempMark.from_index - realStartIndex;
            const endIndex =
              startIndex + (tempMark.to_index - tempMark.from_index);

            indexPosition = index;

            provisoryText = [
              tempText.slice(0, startIndex),
              `[mark=${tempMark.id}]`,
              tempText.slice(startIndex, endIndex),
              `[/mark]`,
              tempText.slice(endIndex),
            ];

            return false;
          }

          realStartIndex += tempText.length;
          return true;
        });

        tempTexts.splice(indexPosition, 1, ...provisoryText);
      });

      const paragraphs = tempTexts.join('').split('\n');

      paragraphs.forEach(paragraph => {
        const splittedParagraph = paragraph.split(
          /(\[mark=.+?\].+?\[\/mark])/gi,
        );

        const contents: WordingContent[] = [];

        splittedParagraph.forEach(part => {
          const tempContent: WordingContent = {
            content: part,
          };

          const splittedParts = part.split(/\[mark=(.+?)\](.+?)\[\/mark]/);

          if (splittedParts.length > 1) {
            const [id, text] = splittedParts.filter(item => item);

            tempContent.content = text;

            const findMark = marks.find(mark => mark.id === id);

            if (findMark) {
              tempContent.mark = findMark;
            }
          }

          contents.push(tempContent);
        });

        tempWording.push({
          id: uuid(),
          contents,
        });
      });

      setWording(tempWording);
    }
  }, [wordingText, marks]);

  useEffect(() => {
    if (!readOnly) {
      if (!wordingRef.current) {
        return;
      }

      const currentWording = wordingRef.current;

      currentWording.addEventListener('mouseup', (event: MouseEvent): void => {
        if (event.button === 0) {
          event.preventDefault();

          // pega o conteúdo da seleção do mouse
          const selection = window.getSelection();

          if (!selection || !selection.rangeCount) {
            return;
          }

          const range = selection.getRangeAt(0);
          const rangeCount = range.endOffset - range.startOffset;

          // verifica se não selecionou nada e aplica a "deseleção"
          if (!rangeCount) {
            deselection();
            return;
          }

          // valida a seleção
          if (!validateMarker(range)) {
            return;
          }

          const paragraphs = [
            ...Array.prototype.slice.call(currentWording.children),
          ] as HTMLElement[];

          const startParagraph = range.startContainer.parentElement;
          const endParagraph = range.endContainer.parentElement;

          if (!startParagraph || !endParagraph) {
            return;
          }

          // pega o indice do paragrafo que foi selecionado
          const startParagraphIndex = paragraphs.indexOf(startParagraph);

          const start = range.startOffset;

          const selectedText = selection.toString();

          let offset = 0;
          paragraphs.every((paragraph, index) => {
            if (index) {
              // Faz-se necessário por conta das quebras de linha de parágrafos
              offset += 1;
            }

            // achei o parágrafo
            if (index === startParagraphIndex) {
              const childNodes = Array.prototype.slice.call(
                paragraph.childNodes,
              );

              childNodes.every(childNode => {
                const childContent =
                  childNode.nodeType === document.ELEMENT_NODE
                    ? childNode.innerHTML
                    : childNode.wholeText;

                if (childNode === range.startContainer) {
                  offset += start;

                  return false;
                }

                offset += childContent.length;

                return true;
              });

              return false;
            }

            offset += paragraph.innerText.length;

            return true;
          });

          setMarkPosition({
            from_index: offset,
            to_index: offset + selectedText.length,
            selectedText,
          });

          deselection();

          setAddMarkModalOpen(true);
        }
      });
    }
  }, [deselection, validateMarker, readOnly]);

  const handleAprimoreWithIA = async (
    mark: string,
    correctorComment: string,
    errorType: 'negative' | 'positive',
    errorCategory: string,
  ) => {
    if (!formAddMarkRef.current) {
      console.log('Formulário ainda não foi inicializado.');
      return;
    }

    console.log('ALUNO ==-==================', student);

    if (!mark) {
      addToast({
        type: 'error',
        title: 'Erro ao aprimorar marcação',
        description: 'Primeiro preencha a marcação para aprimorar com IA.',
      });
      return;
    }

    try {
      setLoadingIa(true);

      const response = await getOpenAIResponse({
        mark,
        correctorComment,
        errorType,
        errorCategory,
        studentName: student?.show_name.split(' ')[0] ?? '',
      });
      console.log('response: ', response);

      console.log('MARCAÇÃO: ', mark);
      console.log('COMENTÁRIO CORRETOR: ', correctorComment);

      formAddMarkRef.current.setFieldValue('description', response);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao aprimorar marcação',
        description:
          'Ocorreu um erro ao aprimorar a marcação. Tente novamente mais tarde.',
      });
    } finally {
      setLoadingIa(false);
    }
  };

  const disableBtnIa = () => {
    if (loadingIa) {
      return true;
    }

    const descriptionValue = formAddMarkRef.current?.getFieldRef('description')
      .props.value;

    const markCategorySelected = formAddMarkRef.current?.getFieldRef(
      'category_id',
    ).state?.value?.label;

    if (markType === 'negative' && !markCategorySelected.length) {
      return true;
    }

    if (!descriptionValue) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Container fontSize={fontSize}>
        {loading && <Loader isFixed />}
        <aside>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
          <div>11</div>
          <div>12</div>
          <div>13</div>
          <div>14</div>
          <div>15</div>
          <div>16</div>
          <div>17</div>
          <div>18</div>
          <div>19</div>
          <div>20</div>
          <div>21</div>
          <div>22</div>
          <div>23</div>
          <div>24</div>
          <div>25</div>
          <div>26</div>
          <div>27</div>
          <div>28</div>
          <div>29</div>
          <div>30</div>
        </aside>

        <div ref={wordingRef} contentEditable={!readOnly} spellCheck="true">
          {wording.map(paragraph => {
            return (
              <div key={paragraph.id} style={{ whiteSpace: 'pre-wrap' }}>
                {paragraph.contents.map(content => {
                  if (content.mark) {
                    return (
                      <Mark
                        key={content.mark.id}
                        id={content.mark.id}
                        background={content.mark.background}
                        color={content.mark.color}
                      >
                        {content.content}
                      </Mark>
                    );
                  }

                  return content.content;
                })}
              </div>
            );
          })}
        </div>
      </Container>

      <Modal
        isOpen={addMarkModalOpen}
        setIsOpen={() => setAddMarkModalOpen(!addMarkModalOpen)}
      >
        {loadingModal && <Loader />}

        <ModalHeader>
          <div>Adicionar Marcação</div>

          <button type="button" onClick={() => setAddMarkModalOpen(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <SelectedText>{markPosition.selectedText}</SelectedText>

        <Form ref={formAddMarkRef} onSubmit={handleAddMark} noValidate>
          <MarkSelection markType={markType}>
            <button type="button" onClick={() => setMarkType('negative')}>
              <MdThumbDown size={16} />
              Marcação Negativa
            </button>

            <button type="button" onClick={() => setMarkType('positive')}>
              <MdThumbUp size={16} />
              Marcação Positiva
            </button>
          </MarkSelection>

          {markType === 'negative' && (
            <Select
              name="category_id"
              label="Marcação"
              options={markCategoriesOptions}
            />
          )}

          <MarkEditor name="description" label="Comentário" />

          <FooterButtons>
            {loadingIa ? (
              <Loader />
            ) : (
              <Button
                type="button"
                color="primary"
                style={{
                  background: 'linear-gradient(90deg, #cd5be2, #ef0e8c)',
                }}
                disabled={
                  !markPosition.selectedText ||
                  !formAddMarkRef.current?.getFieldRef('description').props
                    .value
                }
                onClick={() => {
                  const descriptionValue = formAddMarkRef.current?.getFieldRef(
                    'description',
                  ).props.value;

                  const markCategorySelected = formAddMarkRef.current?.getFieldRef(
                    'category_id',
                  ).state?.value?.label;

                  if (markType === 'negative' && !markCategorySelected.length) {
                    addToast({
                      type: 'error',
                      title: 'Erro ao aprimorar marcação',
                      description:
                        'Não foi possível aprimorar a marcação com IA, tente novamente mais tarde.',
                    });

                    return;
                  }

                  if (!descriptionValue) {
                    addToast({
                      type: 'error',
                      title: 'Erro ao aprimorar marcação',
                      description:
                        'Primeiro preencha a marcação para aprimorar com IA.',
                    });

                    return;
                  }

                  handleAprimoreWithIA(
                    markPosition.selectedText,
                    descriptionValue,
                    markType,
                    markCategorySelected,
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path
                    d="M194.82,151.43l-51.66,19a7.88,7.88,0,0,0-4.69,4.69l-19,51.66a7.92,7.92,0,0,1-14.86,0l-19-51.66a7.88,7.88,0,0,0-4.69-4.69l-51.66-19a7.92,7.92,0,0,1,0-14.86l51.66-19a7.88,7.88,0,0,0,4.69-4.69l19-51.66a7.92,7.92,0,0,1,14.86,0l19,51.66a7.88,7.88,0,0,0,4.69,4.69l51.66,19A7.92,7.92,0,0,1,194.82,151.43Z"
                    opacity="0.2"
                  />
                  <path d="M197.58,129.06l-51.61-19-19-51.65a15.92,15.92,0,0,0-29.88,0L78.07,110l-51.65,19a15.92,15.92,0,0,0,0,29.88L78,178l19,51.62a15.92,15.92,0,0,0,29.88,0l19-51.61,51.65-19a15.92,15.92,0,0,0,0-29.88ZM140.39,163a15.87,15.87,0,0,0-9.43,9.43l-19,51.46L93,172.39A15.87,15.87,0,0,0,83.61,163h0L32.15,144l51.46-19A15.87,15.87,0,0,0,93,115.61l19-51.46,19,51.46a15.87,15.87,0,0,0,9.43,9.43l51.46,19ZM144,40a8,8,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8,8,0,0,1,144,40ZM248,88a8,8,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8,8,0,0,1,248,88Z" />
                </svg>
                Aprimorar com IA
              </Button>
            )}

            <Button type="submit" color="primary">
              Adicionar marcação
            </Button>
          </FooterButtons>
        </Form>
      </Modal>
    </>
  );
};

export default WordingContent;
