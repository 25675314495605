import React, { useRef, useState, useEffect } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { useHistory } from 'react-router-dom';
import { MdAccessTime, MdHistory, MdSchool } from 'react-icons/md';

import { useRect } from '@reactour/utils';
import { Mask } from '@reactour/mask';
import { Popover } from '@reactour/popover';
import { motion, AnimatePresence } from 'framer-motion';

import api from '../../services/api';

import convertSecondsToTime from '../../utils/convertSecondsToTime';

import { MarkProvider } from '../../hooks/mark';
import { MarkPhotoProvider } from '../../hooks/mark-photo';
import { useToast } from '../../hooks/toast';
import { GradesProvider } from '../../hooks/grades';

import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

import SwitchButton from '../../components/SwitchButton';

import CorrectionContent from './CorrectionContent';

import { CorrectionPanel } from './styles';
import LastCorrectionContent from './LastCorrectionContent';
import CorrectionPhotoContent from './CorrectionPhotoContent';
import LastCorrectionPhotoContent from './LastCorrectionPhotoContent';
import Button from '../../components/Button';

interface CorrectionData {
  id: string;
  note: string;
  final_grade: 920;
}

interface CorrectionGrade {
  name:
    | 'competence_1'
    | 'competence_2'
    | 'competence_3'
    | 'competence_4'
    | 'competence_5';
  value: number;
}

interface Grades {
  competence_1: number;
  competence_2: number;
  competence_3: number;
  competence_4: number;
  competence_5: number;
}

interface WordingData {
  id: string;
  created_at: string;
  end_time_in_seconds?: number;
  is_photo: boolean;
  text: string;
  photo_url: string;
  redemption_date?: string;
  status: string;
  font_size: number;
  status_info: {
    name: string;
    color:
      | 'error'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'info'
      | 'warning'
      | 'success';
  };
  student?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
  theme?: {
    id: string;
    name: string;
    thumbnail_url: string;
    material_url: string;
    exam_name: string;
  };
  corrections: CorrectionData[];
}

const Correction: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const CorrectionPanelRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);

  const [showLastCorrection, setShowLastCorrection] = useState(false);

  const [wording, setWording] = useState<WordingData>({} as WordingData);
  const [previousWording, setPreviousWording] = useState({} as WordingData);

  const [grades, setGrades] = useState<Grades>({
    competence_1: 0,
    competence_2: 0,
    competence_3: 0,
    competence_4: 0,
    competence_5: 0,
  });

  const [marks, setMarks] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [updater, setUpdater] = useState([]);
  const ref = useRef(null);
  const sizes = useRect(ref, updater);
  const wrapperRef = useRef(null);

  const [timeLeft, setTimeLeft] = useState(5400);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setUpdater([]);
    });
    return () => {
      window.removeEventListener('scroll', () => {
        setUpdater([]);
      });
    };
  }, [setUpdater]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(oldTimeLeft => {
        if (oldTimeLeft === 0) {
          clearInterval(interval);
          return 0;
        }

        return oldTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      history.push('/');
    }
  }, [timeLeft, history]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<WordingData>('/wordings/next');

        setWording(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        addToast({
          title: 'Ocorreu um erro',
          description:
            'Aparentemente, não há redações a serem corrigidas. Por favor, tente novamente mais tarde.',
          type: 'error',
        });

        history.push('/');
      }
    }

    loadData();
  }, [addToast, history]);

  useEffect(() => {
    async function loadData() {
      try {
        if (!wording.id) {
          return;
        }

        const previousWordingResponse = await api.get<WordingData>(
          `/wordings/${wording.id}/previous`,
        );

        const newPreviousWording = previousWordingResponse.data;

        const [
          previousMarksResponse,
          previousGradesResponse,
        ] = await Promise.all([
          api.get(`/corrections/${newPreviousWording.corrections[0].id}/marks`),
          api.get<CorrectionGrade[]>(
            `/corrections/${newPreviousWording.corrections[0].id}/grades`,
          ),
        ]);

        const newGrades: Grades = {} as Grades;

        previousGradesResponse.data.forEach((data: CorrectionGrade) => {
          newGrades[data.name] = data.value;
        });

        setGrades(newGrades);
        setMarks(previousMarksResponse.data);

        setPreviousWording(previousWordingResponse.data);
        setIsOpen(true);
      } catch (err) {
        // console.log(err);
      }
    }

    loadData();
  }, [wording.id]);

  return (
    <>
      <Header showMenu={false} />

      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ position: 'relative', zIndex: 99999 }}
          >
            <Mask
              sizes={sizes}
              styles={{
                maskWrapper: base => ({ ...base, zIndex: 99999 }),
              }}
            />
            <Popover
              sizes={sizes}
              styles={{
                popover: base => ({ ...base, borderRadius: '8px' }),
              }}
            >
              <p style={{ fontWeight: 'normal', marginBottom: '16px' }}>
                Essa redação possui uma correção anterior e você pode clicar
                nesse botão para exibir ou esconder sempre que precisar.
              </p>

              <div style={{ textAlign: 'right' }}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsOpen(false)}
                >
                  Entendi!
                </Button>
              </div>
            </Popover>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <CorrectionPanel ref={CorrectionPanelRef}>
        <div ref={wrapperRef}>
          <section>
            <MdAccessTime size={48} />

            <div>
              <span>Tempo restante</span>
              <span>{convertSecondsToTime(timeLeft)}</span>
            </div>
          </section>

          <section>
            <MdSchool size={48} />

            <div>
              <span>Modelo de correção</span>
              <span>{wording.theme?.exam_name}</span>
            </div>
          </section>

          <section ref={ref}>
            <MdHistory size={48} />

            <div>
              <span>Correção anterior</span>
              <SwitchButton
                active={showLastCorrection}
                disabled={!previousWording.id}
                size="sm"
                pulse={showLastCorrection}
                onClick={() => {
                  if (!previousWording.id) {
                    return;
                  }

                  setShowLastCorrection(!showLastCorrection);
                }}
              />
            </div>
          </section>

          <section>
            <img
              src={wording.student?.avatar_url}
              alt={wording.student?.show_name}
            />

            <div>
              <span>Nome do aluno</span>
              <span>{wording.student?.show_name}</span>
            </div>
          </section>
        </div>
      </CorrectionPanel>
      <Container style={{ flex: 1 }}>
        {previousWording.id &&
          (previousWording.is_photo ? (
            <MarkPhotoProvider>
              <GradesProvider initialGrades={grades}>
                <LastCorrectionPhotoContent
                  grades={grades}
                  initialMarks={marks}
                  active={showLastCorrection}
                  note={
                    previousWording.corrections &&
                    previousWording.corrections[0].note
                  }
                  wording_id={previousWording.id}
                  photo_url={previousWording.photo_url}
                  theme={previousWording.theme}
                />
              </GradesProvider>
            </MarkPhotoProvider>
          ) : (
            <MarkProvider>
              <GradesProvider initialGrades={grades}>
                <LastCorrectionContent
                  theme={previousWording.theme}
                  text={previousWording.text}
                  grades={grades}
                  note={
                    previousWording.corrections &&
                    previousWording.corrections[0].note
                  }
                  initialMarks={marks}
                  active={showLastCorrection}
                  fontSize={previousWording.font_size}
                />
              </GradesProvider>
            </MarkProvider>
          ))}

        {wording.is_photo ? (
          <MarkPhotoProvider>
            <GradesProvider>
              <CorrectionPhotoContent
                corrected_in={5400 - timeLeft}
                wording_id={wording.id}
                is_photo={wording.is_photo}
                text={wording.text}
                photo_url={wording.photo_url}
                theme={wording.theme}
                isPrevious={showLastCorrection}
                student={wording.student}
              />
            </GradesProvider>
          </MarkPhotoProvider>
        ) : (
          <MarkProvider>
            <GradesProvider>
              <CorrectionContent
                corrected_in={5400 - timeLeft}
                wording_id={wording.id}
                is_photo={wording.is_photo}
                text={wording.text}
                photo_url={wording.photo_url}
                theme={wording.theme}
                fontSize={wording.font_size}
                isPrevious={showLastCorrection}
                student={wording.student}
              />
            </GradesProvider>
          </MarkProvider>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Correction;
