import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { string } from 'yup';
import api from '../../services/api';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';

import { StatusBadge } from './styles';

interface Student {
  id: string;
  show_name: string;
  first_name: string;
  phone_number: string;
}

interface Status {
  name: string;
  background: string;
  color: string;
}

interface transactionData {
  payment_ext: string;
  created_at: Date;
  student: Student;
  affiliate: Student;
  payment_method: string;
  amount: string;
  status_info: Status;
  refuse_reason: string;
}

interface columnData {
  label: string;
  field:
    | 'payment_ext'
    | 'created_at'
    | 'student'
    | 'payment_method'
    | 'refuse_reason'
    | 'affiliate'
    | 'amount'
    | 'status_info';
  transform?(
    value: string | Date | Student | Status,
    row?: transactionData,
  ): string | React.ReactElement;
}

const Transaction: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<transactionData[]>([]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Código',
        field: 'payment_ext',
      },
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = format(
            parseISO(created_at),
            "dd/MM/yyyy 'às' HH:mm",
            { locale: ptBR },
          );

          return formattedDate;
        },
      },
      {
        label: 'Aluno',
        field: 'student',
        transform: (student: Student) => {
          return (
            <Link to={`/alunos/visualizar/${student.id}`} target="blank">
              {student.show_name}
            </Link>
          );
        },
      },
      {
        label: 'Valor',
        field: 'amount',
      },
      {
        label: 'Status',
        field: 'status_info',
        transform: (status: Status) => {
          return (
            <StatusBadge background={status.background} color={status.color}>
              {status.name}
            </StatusBadge>
          );
        },
      },
      {
        label: 'Afiliado',
        field: 'affiliate',
        transform: (affiliate: Student) => affiliate?.show_name,
      },
      {
        label: ' ',
        field: 'refuse_reason',
        transform: (refuse_reason: string) => {
          return refuse_reason === 'Transação aprovada com sucesso' ? (
            ''
          ) : (
            <span>{refuse_reason}</span>
          );
        },
      },
      {
        label: '',
        field: 'student',
        transform: (student: Student) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/alunos/visualizar/${student.id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
              <li>
                <Link
                  to={`//api.whatsapp.com/send?phone=+55${student.phone_number}&text=Olá%2C%20${student.first_name}!`}
                  target="_blank"
                >
                  <MdKeyboardArrowRight size={18} />
                  WhatsApp
                </Link>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/transactions', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Vendas" items={[{ title: 'Vendas' }]} />

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.payment_ext}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhuma venda encontrada</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Transaction;
