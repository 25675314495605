import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import { useHistory } from 'react-router-dom';
import {
  MdError,
  MdFileDownload,
  MdSend,
  MdClose,
  MdZoomIn,
} from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useMark } from '../../../hooks/mark-photo';
import { useGrades } from '../../../hooks/grades';
import { useToast } from '../../../hooks/toast';

import AppError from '../../../errors/AppError';

import getValidationErrors from '../../../utils/getValidationErrors';

import WordingContent from '../../../containers/WordingContent';
import CorrectionGrades from '../../../containers/CorrectionGrades';

import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Modal, { ModalHeader } from '../../../components/Modal';

import { Container, WordingTitle, ReportButton } from './styles';
import Breadcrumbs from '../../../containers/Breadcrumbs';
import WordingPhotoContent from '../../../containers/WordingPhotoContent';
import ModalConfirm from '../../../components/Modal/Confirm';
import Student from '../../../interfaces/Student';

interface CorrectionPhotoContentProps {
  wording_id: string;
  corrected_in?: number;
  is_photo: boolean;
  text: string;
  photo_url: string;
  theme?: {
    name: string;
    material_url: string;
    exam_name: string;
  };
  initialMarks?: any[];
  isPrevious?: boolean;
  student?: Student;
}

interface CorrectionRegistrationFormData {
  note: string;
  competence_1: number;
  competence_2: number;
  competence_3: number;
  competence_4: number;
  competence_5: number;
}

const CorrectionPhotoContent: React.FC<CorrectionPhotoContentProps> = ({
  corrected_in,
  wording_id,
  is_photo,
  text,
  photo_url,
  theme,
  initialMarks,
  isPrevious = false,
  student,
}) => {
  const { addToast } = useToast();
  const { marks, setInitialMarks } = useMark();
  const { finalGrade } = useGrades();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const formCancelWordingRef = useRef<FormHandles>(null);

  const [zoom, setZoom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [tempFormData, setTempFormData] = useState<
    CorrectionRegistrationFormData
  >({} as CorrectionRegistrationFormData);

  const [cancelWordingModal, setCancelWordingModal] = useState(false);
  const [confirmZeroGradeModal, setConfirmZeroGradeModal] = useState(false);

  useEffect(() => {
    if (initialMarks) {
      setInitialMarks(initialMarks);
    }
  }, [initialMarks, setInitialMarks]);

  const handleSubmit = useCallback(
    async (formData: CorrectionRegistrationFormData) => {
      setLoading(true);

      try {
        const positiveMarks = marks.filter(mark => mark.type === 'positive');

        if (!positiveMarks.length) {
          throw new AppError(
            'Ocorreu um erro ao enviar a correção',
            'Por favor, realize ao menos uma marcação positiva e tente novamente.',
          );
        }

        const schema = Yup.object().shape({
          note: Yup.string().required('Comentários obrigatórios'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.post('corrections', {
          wording_id,
          note: formData.note,
          final_grade: finalGrade,
          corrected_in,
          marks: marks.map(mark => {
            const {
              background,
              color,
              title,
              description,
              mark_id,
              x1,
              x2,
              y1,
              y2,
              type,
            } = mark;

            return {
              background,
              color,
              title,
              description,
              mark_id,
              x1,
              x2,
              y1,
              y2,
              type,
            };
          }),
          grades: [
            { name: 'competence_1', value: formData.competence_1 },
            { name: 'competence_2', value: formData.competence_2 },
            { name: 'competence_3', value: formData.competence_3 },
            { name: 'competence_4', value: formData.competence_4 },
            { name: 'competence_5', value: formData.competence_5 },
          ],
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A correção foi realizada com sucesso!',
        });

        setLoading(false);

        history.push('/dashboard');
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err instanceof AppError) {
          addToast({
            type: 'error',
            title: err.title,
            description: err.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadaastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [marks, finalGrade, wording_id, addToast, history, corrected_in],
  );

  const handleCheckZeroGrade = useCallback(
    formData => {
      const grade =
        formData.competence_1 +
        formData.competence_2 +
        formData.competence_3 +
        formData.competence_4 +
        formData.competence_5;

      if (grade === 0) {
        setTempFormData(formData);
        setConfirmZeroGradeModal(true);
      } else {
        handleSubmit(formData);
      }
    },
    [handleSubmit],
  );

  const handleCancelWording = useCallback(
    async (formData: { cancellation_reason: string }) => {
      setLoadingModal(true);

      try {
        const schema = Yup.object().shape({
          cancellation_reason: Yup.string().required('Motivo obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.delete(`/wordings/${wording_id}`, {
          data: formData,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cancelamento',
          description: 'A redação foi cancelada com sucesso!',
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formCancelWordingRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cancelamento',
          description: 'Ocorreu um erro na exclusão, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast, wording_id, history],
  );

  const handleCopyWording = useCallback(() => {
    if (is_photo) {
      return;
    }

    setLoading(true);

    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        navigator.clipboard.writeText(text).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'A redação foi copiada com sucesso.',
              type: 'success',
            });

            setLoading(false);
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar a redação.',
              type: 'error',
            });

            setLoading(false);
          },
        );
      }
    });
  }, [is_photo, text, addToast]);

  return (
    <>
      <Breadcrumbs
        title="Corrigir redação"
        items={[{ title: 'Corrigir redação' }]}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ReportButton
            type="button"
            onClick={() => {
              setCancelWordingModal(true);
            }}
          >
            <MdError size={20} />
            Relatar um problema
          </ReportButton>

          <Button
            type="submit"
            color="secondary"
            form="formCorrection"
            disabled={isPrevious}
            disabledMessage="Você está visualizando a correção anterior!"
          >
            <MdSend size={16} />
            Enviar correção
          </Button>
        </div>
      </Breadcrumbs>

      <Container>
        {loading && <Loader isFixed />}

        <Row>
          <Col md={12}>
            <Card>
              <WordingTitle>
                <div>
                  <h2>{theme?.name}</h2>
                  <ul>
                    <li>
                      <a target="blank" href={theme?.material_url}>
                        <MdFileDownload size={16} />
                        Material de apoio
                      </a>
                    </li>
                    <li>
                      <button type="button" onClick={handleCopyWording}>
                        <MdFileDownload size={16} />
                        Copiar redação
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => setZoom(!zoom)}>
                        <MdZoomIn size={16} />
                        Zoom
                      </button>
                    </li>
                  </ul>
                </div>
              </WordingTitle>

              {is_photo ? (
                <WordingPhotoContent
                  photo_url={photo_url}
                  zoom={zoom}
                  setZoom={setZoom}
                  student={student}
                />
              ) : (
                <WordingContent wordingText={text} student={student} />
              )}
            </Card>
          </Col>

          <Col md={12} style={{ marginBottom: '40px' }}>
            <Form
              ref={formRef}
              onSubmit={handleCheckZeroGrade}
              noValidate
              id="formCorrection"
            >
              <CorrectionGrades />

              <TextArea
                name="note"
                label="Comentários"
                required
                placeholder="Insira um breve comentário final sobre a redação e o que pode ser melhorado."
                rows={8}
              />

              <Button
                type="submit"
                color="secondary"
                disabled={isPrevious}
                disabledMessage="Você está visualizando a correção anterior!"
              >
                <MdSend size={16} />
                Enviar correção
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={cancelWordingModal}
        setIsOpen={() => setCancelWordingModal(!cancelWordingModal)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Relatar um problema</div>

          <button type="button" onClick={() => setCancelWordingModal(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formCancelWordingRef}
          onSubmit={handleCancelWording}
          id="cancelWording"
          noValidate
        >
          <Select
            label="Motivo"
            name="cancellation_reason"
            options={[
              {
                label: 'A redação está em branco',
                value: 'is_blank',
              },
              {
                label: 'A redação está ilegível',
                value: 'is_unreadable',
              },
              {
                label: 'A redação está idêntica à anterior',
                value: 'is_duplicated',
              },
              {
                label: 'A redação está de lado, impossibilitando a correção',
                value: 'is_rotated',
              },
              {
                label: 'Tema diferente do proposto pela plataforma',
                value: 'different_theme',
              },
            ]}
          />

          <Button
            type="submit"
            color="primary"
            style={{ marginTop: '16px' }}
            form="cancelWording"
          >
            Confirmar
          </Button>
        </Form>

        <ModalConfirm
          title="Atenção!"
          confirmText="Confirmar"
          cancelText="Cancelar"
          text="Tem certeza de que deseja dar nota ZERO em uma ou mais competências?"
          onConfirm={() => handleSubmit(tempFormData)}
          isOpen={confirmZeroGradeModal}
          isLoading={false}
          setIsOpen={() => setConfirmZeroGradeModal(!confirmZeroGradeModal)}
        >
          <div style={{ marginBottom: '2rem' }}>
            <span
              style={{
                background: 'red',
                color: '#fff',
                padding: '6px 12px',
                marginBottom: '1rem',
                display: 'inline-block',
              }}
            >
              <strong>C1:</strong> {tempFormData.competence_1},
              <strong>C2:</strong> {tempFormData.competence_2},
              <strong>C3:</strong> {tempFormData.competence_2},
              <strong>C4:</strong> {tempFormData.competence_4},
              <strong>C5:</strong> {tempFormData.competence_5}
            </span>
            <p>
              <strong>
                NOTA FINAL:{' '}
                {tempFormData.competence_1 +
                  tempFormData.competence_2 +
                  tempFormData.competence_3 +
                  tempFormData.competence_4 +
                  tempFormData.competence_5}
              </strong>
            </p>
          </div>
        </ModalConfirm>
      </Modal>
    </>
  );
};

export default CorrectionPhotoContent;
