import React, { useRef, useState, useCallback } from 'react';

import Magnifier from 'react-magnifier';

import { useMark } from '../../hooks/mark-photo';

import Loader from '../../components/Loader';

import ModalMarkRegistration from '../ModalMarkRegistration';
import Mark from './Mark';

import { Container, Overlay, WordingPhotoContainer, Zoom } from './styles';
import Student from '../../interfaces/Student';

interface WordingPhotoContentProps {
  photo_url: string;
  readOnly?: boolean;
  zoom?: boolean;
  setZoom?(value: boolean): void;
  student?: Student;
}

interface ICoordinate {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

const WordingPhotoContent: React.FC<WordingPhotoContentProps> = ({
  photo_url,
  readOnly = false,
  zoom = false,
  setZoom,
  student,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const { marks } = useMark();

  const [isSelecting, setIsSelecting] = useState(false);
  const [isSmall, setIsSmall] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addMarkModalOpen, setAddMarkModalOpen] = useState(false);

  const [tempDescription, setTempDescription] = useState('');
  const [tempCategoryId, setTempCategoryId] = useState('');
  const [tempCoordinate, setTempCoordinate] = useState<ICoordinate>({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  } as ICoordinate);

  const calculateMarker = useCallback(({ x1, y1, x2, y2 }: ICoordinate) => {
    const minAx = Math.min(x1, x2);
    const minAy = Math.min(y1, y2);
    const maxAx = Math.max(x1, x2);
    const maxAy = Math.max(y1, y2);

    return {
      left: minAx,
      top: minAy,
      width: maxAx - minAx,
      height: maxAy - minAy,
    };
  }, []);

  const validateMarker = useCallback(() => {
    const minAx = Math.min(tempCoordinate.x1, tempCoordinate.x2);
    const minAy = Math.min(tempCoordinate.y1, tempCoordinate.y2);
    const maxAx = Math.max(tempCoordinate.x1, tempCoordinate.x2);
    const maxAy = Math.max(tempCoordinate.y1, tempCoordinate.y2);

    const hasMarkInPosition = marks.find(mark => {
      const minBx = Math.min(mark.x1, mark.x2);
      const minBy = Math.min(mark.y1, mark.y2);
      const maxBx = Math.max(mark.x1, mark.x2);
      const maxBy = Math.max(mark.y1, mark.y2);

      const aLeftOfB = maxAx < minBx;
      const aRightOfB = minAx > maxBx;
      const aAboveB = minAy > maxBy;
      const aBelowB = maxAy < minBy;

      return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
    });

    if (hasMarkInPosition) {
      return false;
    }

    if (maxAx - minAx < 24 || maxAy - minAy < 24) {
      return false;
    }

    return true;
  }, [marks, tempCoordinate]);

  const calculateOverlay = useCallback(() => {
    const { left, top, width, height } = calculateMarker(tempCoordinate);

    if (!overlayRef.current) {
      return;
    }

    setIsSmall(width < 24 || height < 24);

    overlayRef.current.style.left = `${left}px`;
    overlayRef.current.style.top = `${top}px`;
    overlayRef.current.style.width = `${width}px`;
    overlayRef.current.style.height = `${height}px`;
  }, [tempCoordinate, calculateMarker]);

  const getPosition = (e: React.MouseEvent): number[] => {
    const eventTarget = e.currentTarget as Element;

    const parentOffset = eventTarget.getBoundingClientRect();

    const x = e.clientX - parentOffset.left;
    const y = e.clientY - parentOffset.top;

    return [x, y];
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    const [x1, y1] = getPosition(e);

    setTempCoordinate({ x1, y1, x2: x1, y2: y1 });

    calculateOverlay();

    setIsSelecting(true);
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    setShowOverlay(false);

    if (!validateMarker()) {
      return;
    }

    setAddMarkModalOpen(true);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    setShowOverlay(isSelecting);

    const [x2, y2] = getPosition(e);

    setTempCoordinate(old => ({ ...old, x2, y2 }));

    calculateOverlay();
  };

  return (
    <>
      <Container>
        {loading && <Loader isFixed />}
        <WordingPhotoContainer
          onMouseDown={e => {
            !readOnly && handleMouseDown(e);
          }}
          onMouseUp={e => !readOnly && handleMouseUp()}
          onMouseMove={e => !readOnly && handleMouseMove(e)}
        >
          <Overlay isVisible={showOverlay} isSmall={isSmall} ref={overlayRef} />

          <img
            src={photo_url}
            alt=""
            draggable={false}
            style={{ cursor: readOnly ? 'default' : 'crosshair' }}
          />
          {zoom && (
            <Zoom
              onClick={() => {
                setZoom && setZoom(false);
              }}
              draggable={false}
            >
              <Magnifier
                src={photo_url}
                width={890}
                mgWidth={300}
                mgHeight={300}
                zoomFactor={1.1}
              />
            </Zoom>
          )}
        </WordingPhotoContainer>

        {marks.map(mark => (
          <Mark
            key={mark.id}
            id={mark.id}
            background={mark.background}
            color={mark.color}
            readOnly={readOnly}
          />
        ))}
      </Container>

      <ModalMarkRegistration
        description={tempDescription}
        tempCategoryId={tempCategoryId}
        x1={tempCoordinate.x1}
        y1={tempCoordinate.y1}
        x2={tempCoordinate.x2}
        y2={tempCoordinate.y2}
        isOpen={addMarkModalOpen}
        setIsOpen={setAddMarkModalOpen}
        setTempDescription={setTempDescription}
        setTempCategoryId={setTempCategoryId}
        student={student}
      />
    </>
  );
};

export default WordingPhotoContent;
