import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { MdClose, MdThumbDown, MdThumbUp } from 'react-icons/md';

import { useToast } from '../../hooks/toast';
import { useMark } from '../../hooks/mark-photo';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import getCompetenceColor from '../../utils/getCompetenceColor';

import Loader from '../../components/Loader';

import Modal, { ModalHeader } from '../../components/Modal';
import Select from '../../components/Select';
import MarkEditor from '../../components/MarkEditor';
import Button from '../../components/Button';

import { MarkSelection } from './styles';
import { FooterButtons } from '../WordingContent/styles';
import { getOpenAIResponse } from '../../utils/ia';
import Student from '../../interfaces/Student';

interface Category {
  value: string;
  label: string;
  competence: number;
}

interface GroupedCategory {
  label: string;
  options: Category[];
}

interface MarkRegistrationFormData {
  category_id: string;
  description: string;
}

interface ModalMarkRegistrationProps {
  student?: Student;
  tempCategoryId?: string;
  description?: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  setTempDescription?(value: string): void;
  setTempCategoryId?(value: string): void;
}

const ModalMarkRegistration: React.FC<ModalMarkRegistrationProps> = ({
  tempCategoryId,
  description,
  x1,
  y1,
  x2,
  y2,
  isOpen,
  setIsOpen,
  setTempDescription,
  setTempCategoryId,
  student,
}) => {
  const { addToast } = useToast();
  const { addMark } = useMark();

  const formAddMarkRef = useRef<FormHandles>(null);

  const [loadingIa, setLoadingIa] = useState(false);

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<Category[]>([]);
  const [groupedCategories, setGroupedCategories] = useState<GroupedCategory[]>(
    [],
  );

  const [isNegative, setIsNegative] = useState(true);

  const handleAddMark = useCallback(
    async (formData: MarkRegistrationFormData) => {
      setLoading(true);

      // setTempDescription && setTempDescription(formData.description);
      // setTempCategoryId && setTempCategoryId(formData.category_id);
      // console.log(formData);

      formAddMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          category_id: isNegative
            ? Yup.string().required('Categoria obrigatória').nullable()
            : Yup.string(),
          description: isNegative
            ? Yup.string()
            : Yup.string().required('Comentário obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro da marcação',
          description:
            'Ocorreu um erro no cadastro da marcação, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }

      let competence = 0;
      let title = 'Marcação positiva';

      if (isNegative) {
        const category = categories.find(
          findCategory => findCategory.value === formData.category_id,
        );

        if (category) {
          competence = category.competence;
          title = category.label;
        }
      }

      const competenceColor = getCompetenceColor(competence);

      addMark({
        x1,
        y1,
        x2,
        y2,
        background: competenceColor.background,
        color: competenceColor.color,
        title,
        description: formData.description,
        type: isNegative ? 'negative' : 'positive',
      });

      setIsOpen(false);
    },
    [addMark, addToast, categories, isNegative, x1, y1, x2, y2, setIsOpen],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<Category[]>('/marks/list');

        setCategories(response.data);

        const groups: GroupedCategory[] = [];

        response.data.forEach(category => {
          const competence = `Competência ${category.competence}`;

          const groupIndex = groups.findIndex(
            groupedCategory => groupedCategory.label === competence,
          );

          if (groupIndex === -1) {
            groups.push({
              label: competence,
              options: [category],
            });
          } else {
            groups[groupIndex].options.push(category);
          }
        });

        setGroupedCategories(groups);

        setLoading(false);

        if (tempCategoryId) {
          formAddMarkRef.current?.setData({
            category_id: tempCategoryId,
          });

          console.log(tempCategoryId);
        }
      } catch (error) {
        setLoading(false);

        addToast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
          type: 'error',
        });
      }
    }

    loadData();
  }, [addToast, tempCategoryId]);

  const handleAprimoreWithIA = async (
    correctorComment: string,
    errorType: 'negative' | 'positive',
    errorCategory: string,
  ) => {
    if (!formAddMarkRef.current) {
      console.log('Formulário ainda não foi inicializado.');
      return;
    }

    try {
      setLoadingIa(true);

      const response = await getOpenAIResponse({
        correctorComment,
        errorType,
        errorCategory,
        studentName: student?.show_name.split(' ')[0] ?? '',
      });
      console.log('response: ', response);
      console.log('COMENTÁRIO CORRETOR: ', correctorComment);

      formAddMarkRef.current.setFieldValue('description', response);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao aprimorar marcação',
        description:
          'Ocorreu um erro ao aprimorar a marcação. Tente novamente mais tarde.',
      });
    } finally {
      setLoadingIa(false);
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)}>
      {loading && <Loader />}

      <ModalHeader>
        <div>Adicionar Marcação</div>

        <button type="button" onClick={() => setIsOpen(false)}>
          <MdClose size={20} />
        </button>
      </ModalHeader>

      <Form ref={formAddMarkRef} onSubmit={handleAddMark} noValidate>
        <MarkSelection isNegative={isNegative}>
          <button type="button" onClick={() => setIsNegative(true)}>
            <MdThumbDown size={16} />
            Marcação Negativa
          </button>

          <button type="button" onClick={() => setIsNegative(false)}>
            <MdThumbUp size={16} />
            Marcação Positiva
          </button>
        </MarkSelection>

        {isNegative && (
          <Select
            name="category_id"
            label="Marcação"
            options={groupedCategories}
          />
        )}

        <MarkEditor
          name="description"
          label="Comentário"
          defaultValue={description}
        />

        <FooterButtons>
          {loadingIa ? (
            <Loader />
          ) : (
            <Button
              type="button"
              color="primary"
              style={{
                background: 'linear-gradient(90deg, #cd5be2, #ef0e8c)',
              }}
              disabled={
                !formAddMarkRef.current?.getFieldRef('description').props.value
              }
              onClick={() => {
                const descriptionValue = formAddMarkRef.current?.getFieldRef(
                  'description',
                ).props.value;

                const markCategorySelected = formAddMarkRef.current?.getFieldRef(
                  'category_id',
                ).state?.value?.label;

                if (isNegative && !markCategorySelected) {
                  addToast({
                    type: 'error',
                    title: 'Erro ao aprimorar marcação',
                    description:
                      'Não foi possível aprimorar a marcação com IA, tente novamente mais tarde.',
                  });

                  return;
                }

                if (!descriptionValue) {
                  addToast({
                    type: 'error',
                    title: 'Erro ao aprimorar marcação',
                    description:
                      'Primeiro preencha a marcação para aprimorar com IA.',
                  });

                  return;
                }

                handleAprimoreWithIA(
                  descriptionValue,
                  isNegative ? 'negative' : 'positive',
                  markCategorySelected,
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path
                  d="M194.82,151.43l-51.66,19a7.88,7.88,0,0,0-4.69,4.69l-19,51.66a7.92,7.92,0,0,1-14.86,0l-19-51.66a7.88,7.88,0,0,0-4.69-4.69l-51.66-19a7.92,7.92,0,0,1,0-14.86l51.66-19a7.88,7.88,0,0,0,4.69-4.69l19-51.66a7.92,7.92,0,0,1,14.86,0l19,51.66a7.88,7.88,0,0,0,4.69,4.69l51.66,19A7.92,7.92,0,0,1,194.82,151.43Z"
                  opacity="0.2"
                />
                <path d="M197.58,129.06l-51.61-19-19-51.65a15.92,15.92,0,0,0-29.88,0L78.07,110l-51.65,19a15.92,15.92,0,0,0,0,29.88L78,178l19,51.62a15.92,15.92,0,0,0,29.88,0l19-51.61,51.65-19a15.92,15.92,0,0,0,0-29.88ZM140.39,163a15.87,15.87,0,0,0-9.43,9.43l-19,51.46L93,172.39A15.87,15.87,0,0,0,83.61,163h0L32.15,144l51.46-19A15.87,15.87,0,0,0,93,115.61l19-51.46,19,51.46a15.87,15.87,0,0,0,9.43,9.43l51.46,19ZM144,40a8,8,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8,8,0,0,1,144,40ZM248,88a8,8,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8,8,0,0,1,248,88Z" />
              </svg>
              Aprimorar com IA
            </Button>
          )}

          <Button type="submit" color="primary">
            Adicionar marcação
          </Button>
        </FooterButtons>
      </Form>
    </Modal>
  );
};

export default ModalMarkRegistration;
