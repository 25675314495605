import styled, { css } from 'styled-components';

interface MarkSelectionProps {
  markType: 'positive' | 'negative';
}

interface ContainerProps {
  fontSize: number;
}

const colorVariations = {
  positive: css`
    &:last-child {
      color: ${({ theme }) => theme.colors.success};
      border-color: ${({ theme }) => theme.colors.success};
    }
  `,
  negative: css`
    &:first-child {
      color: ${({ theme }) => theme.colors.danger};
      border-color: ${({ theme }) => theme.colors.danger};
    }
  `,
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 890px;
  margin: 0 auto;
  margin-bottom: 40px;

  > aside {
    position: absolute;
    left: 5px;
    top: 31px;
    line-height: 30px;
    font-size: 11px;
    color: #7f7e7e;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      width: 0px;
      top: -31px;
      left: 39px;
      bottom: 2px;
      border-left: 2px solid rgba(255, 0, 0, 0.4);
      z-index: 1;
    }
  }

  > div {
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
    text-align: justify;
    position: relative;
    padding: 30px 5px 0px 50px;
    border-radius: 12px;
    background: white;
    background-image: repeating-linear-gradient(
      white 0px,
      white 24px,
      steelblue 25px
    );
    background-size: 100% 30px;
    color: #3e3f5e;
    line-height: 30px;
    font-weight: 600;
    min-height: 929px;
    font-size: ${({ fontSize }) => `${fontSize}px`};
    resize: vertical;
    margin: 0 auto;

    > div {
      text-indent: 28px;
    }
  }
`;

export const SelectedText = styled.blockquote`
  margin-bottom: 40px;
  font-style: italic;
  text-align: center;
`;

export const MarkSelection = styled.div<MarkSelectionProps>`
  margin-bottom: 20px;
  display: flex;

  button {
    background: none;
    flex: 1;
    padding: 16px;
    background: ${({ theme }) => theme.input.background};
    border: ${({ theme }) => theme.input.border};
    color: ${({ theme }) => theme.input.text};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-width: 2px;
    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.input.placeholder};
    }

    & + button {
      margin-left: 16px;
    }

    svg {
      color: inherit;
      margin-right: 8px;
    }

    ${props => colorVariations[props.markType]}
  }
`;

export const FooterButtons = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;
